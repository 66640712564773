@import "../../assets/styles/variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $light-black;
  z-index: 5;
  .modal-main {
    position: fixed;
    background: black;
    width: 60%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 48px;
    margin: 0 auto;
    color: $light-brown;
    .close {
      width: 30px;
      height: 24px;
      cursor: pointer;
      float: right;
      margin: 20px 10px;
    }
    .modal-content {
      padding: 50px;
      background-image: url("../../assets/images/modal-background.png");
      background-position: center center;
      background-repeat: no-repeat;
      .participate {
        font-family: $utopia;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 24px;
        letter-spacing: 0.08em;
        margin: 72px 0;
      }
      .website-url {
        font-family: $utopia;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.08em;
        margin-bottom: 64px;
        color: $light-brown;
      }
      .join-team {
        font-family: $poppins;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.08em;
        margin-bottom: 48px;
      }
    }
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .modal{
    .modal-main {
      width: 80%;
      .modal-content{
        padding: 0;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .modal{
    .modal-main {
      width: 85%;
      height: 650px;
      overflow: auto;
      .modal-content{
        padding: 20px;
      }
    }
  }
}
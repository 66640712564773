@import "../../assets/styles/variables";
@import url("../PageBase.scss");

.previous-years-wrapper {
  z-index: 3;
  .general-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .title {
    font-family: $utopia;
    font-weight: 700;
    font-style: italic;
    padding: 3rem 0 0 3rem;
    margin: 0;
    font-size: 3rem;
    font-weight: bold;
    line-height: 97%;
    color: $light-orange;
    text-align: center;
  }

  .year {
    font-family: $poppins;
    font-weight: 900;
    margin: 0;
    font-size: 12rem;
    color: $light-orange;
    line-height: 14rem;
  }
}

.years-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .year {
    position: relative;
    font-family: $poppins;
    font-weight: 900;
  }

  div {
    &.badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -1.2rem;
      right: -1rem;
      background-color: $red;
      color: white;
      border-radius: 5rem;
      padding: 0.2rem;
      width: 10rem;
      transform: rotate(10deg);
      border: 0.3rem black solid;

      &.yellow {
        background-color: $orange;
        transform: rotate(-10deg);
      }

      .title {
        padding: 0;
        font-size: 0.7rem;
        color: white;
        line-height: 1.3rem;
      }

      .title {
        span {
          &.description {
            font-size: 1.3rem;
            font-family: $poppins;
            font-weight: 900;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .previous-years-wrapper {
    .year {
      font-size: 10rem;
      line-height: 17rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .previous-years-wrapper {
    .year {
      font-size: 15rem;
      line-height: 17rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .previous-years-wrapper {
    .year {
      font-size: 17rem;
      line-height: 19rem;
    }
  }

  .years-wrapper {
    div {
      .title {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }
      &.badge {
        width: 11rem;
        padding: 0.5rem;
        .title {
          font-size: 0.8rem;

          span {
            &.description {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

@import "../../../assets/styles/variables";

.person-wrapper {
  display: flex;
  flex-direction: column;
  color: black;
  padding-bottom: 1vw;
  margin-left: 24px;
  cursor: pointer;
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -moz-transition: all 1s ease; /* Firefox */
  -ms-transition: all 1s ease; /* IE 9 */
  -o-transition: all 1s ease; /* Opera */
  transition: all 1s ease;
  float: left;
  position: relative;
  opacity: 0;

  .flex {
    display: flex;
    flex-direction: column;
    transition: opacity 0.7s ease-in-out;
    &.inactive{
      opacity: 0;
  //     transition-property: all;
	// transition-duration: .5s;
	// transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  // transition: .4s ease-in-out;
  animation: slide-up .6s cubic-bezier(0.4, 0, 0.2, 1) 500ms backwards;
    }
  
    &.fadeIn{
      opacity: 1;
    }
  }


  // &.fadeOut {
  //       animation: fadeUp;
  //       animation-delay: 0.5s;
  // }

  @for $i from 1 through 13 {
    &.active{
      &:nth-child(#{$i}n) {
        animation: fadeInRight 1s ease-in-out;
        animation-delay: #{$i * 0.5}s;
        animation-fill-mode: forwards;
      }
    }
  }

  img {
    width: 9.5vw;
    border-radius: 2.5vw 2.5vw 0 0;
  }

  .captain {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    color: $white;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $light-orange;
    height: 3.5vw;
    width: 9.5vw;
    font-weight: 700;
    border-radius: 0 0 2.5vw 2.5vw;
    text-align: center;
    line-height: 1vw;
    padding-top: 5px;
    font-size: 1.2vw;

    span {
      padding-top: 0.5vw;
      font-weight: 300;
    }


  }

  .is-visible {
    animation: fadeInRight 0.5s ease-in-out;
    animation-delay: 0.5s;
  }
}

.person-wrapper {
  &:hover {
    -webkit-transform: scale(1.08); /* Safari and Chrome */
    -moz-transform: scale(1.08); /* Firefox */
    -ms-transform: scale(1.08); /* IE 9 */
    -o-transform: scale(1.08); /* Opera */
    transform: scale(1.08);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  0% { height: 227px }
  10% { height: 0; opacity: 0; }  
  90% { height: 0; opacity: 0 }
  100% { height: 227px }
}
@import "../../assets/styles/variables";

.mustache-wrapper {
  .arrow{
    height: 16px;
    margin: 4px 0 0 8px;
  }
  .main-text {
    display: block;
    color: $light-orange;
    position: absolute;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    line-height: 2rem;
    margin-top: 2rem;
    z-index: 1;
    .participate-link {
      font-family: $utopia;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.08em;
      color: $light-brown;
      margin-right: 48px;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    span {
      font-family: $utopia;
      font-weight: 700;
      font-style: italic;
    }

    .info1,
    .info2 {
      opacity: 1;
      span {
        display: inline-block;
        animation: glitch1 0.5s;
      }
    }

    @keyframes glitch1 {
      0% {
        transform: none;
        opacity: 1;
      }
      50% {
        transform: skew(-2deg, 0.6deg);
        opacity: 0.75;
        text-shadow:-5px 0 red;
      }
      100% {
        transform: none;
        opacity: 1;
      }
    }
  }

  .fade-out {
    animation: fade-out 0.5s forwards;
    animation-delay: 1.4s;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  .enter-button {
    position: absolute;
    bottom: 6vh;
    background-color: $light-orange;
    border-radius: 100%;
    width: 30vh;
    height: 7vh;
    font-family: $utopia;
    font-weight: 400;
    font-size: 2.2vh;
    border: 0;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .mustache-wrapper {
    .main-text {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .mustache-wrapper {
    .main-text {
      font-size: 3rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .mustache-wrapper {
    .main-text {
      font-size: 4rem;
    }
  }
}

@keyframes flip-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

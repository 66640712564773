@import "./fonts";

/* fonts */
$poppins: "poppins", sans-serif;
$utopia: "utopia-std, serif";

/* colors */
$light-orange: #d4c5ae;
$light-grey: #d9d9d9;
$orange: #ffaa2b;
$red: #ff5139;
$white: #ffffff;
$black: #000000;
$light-brown: #D4C5AD;
$light-black: #1E1E1E;
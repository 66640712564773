@import "../../assets/styles/variables";

.loading-wrapper {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $poppins;
  color: white;
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: 999;
}

.loading-percentage-wrapper {
  position: absolute;
  height: 69px;
}

.loading-percentage {
  position: absolute;
  left: 3px;
  bottom: 2px;
}

.mustache-loading-bar-wrapper {
  position: relative;
  margin-top: 24px;
  background-image: url("./images/mustache.svg");
  width: 284px;
  height: 69px;
}

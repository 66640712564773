@import "../assets/styles/variables";

.page-base-wrapper {
  font-family: $poppins;
  font-weight: 700;
  font-style: normal;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url("../assets/images/background.png");
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;

  .content-wrapper {
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100vh;
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * poppins:
 *   - http://typekit.com/eulas/00000000000000007735fdb1
 *   - http://typekit.com/eulas/00000000000000007735fdb4
 *   - http://typekit.com/eulas/00000000000000007735fdb8
 *   - http://typekit.com/eulas/00000000000000007735fdbb
 * utopia-std:
 *   - http://typekit.com/eulas/0000000000000000000131a1
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-10-19 08:15:32 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=bte2hkm&ht=tk&f=13381.37515.37516.37521.37522&a=18486931&app=typekit&e=css");

@font-face {
  font-family: "utopia-std";
  src: url("https://use.typekit.net/af/4bab48/0000000000000000000131a1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4bab48/0000000000000000000131a1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4bab48/0000000000000000000131a1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/l?subset_id=2&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/d?subset_id=2&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc20f3/00000000000000007735fdb1/30/a?subset_id=2&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/l?subset_id=2&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/d?subset_id=2&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/a?subset_id=2&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/l?subset_id=2&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/d?subset_id=2&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/522bda/00000000000000007735fdb8/30/a?subset_id=2&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "poppins";
  src: url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

.tk-utopia-std {
  font-family: "utopia-std", serif;
}
.tk-poppins {
  font-family: "poppins", sans-serif;
}

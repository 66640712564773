@import "../../assets/styles/variables";
@import "../PageBase.scss";

.participants-wrapper {
  z-index: 2;
  .general-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .title {
    font-family: $utopia;
    font-weight: 700;
    font-style: italic;
    padding: 2.6vw 0 0 3.2vw;
    margin: 0;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 97%;
    color: $light-orange;
    text-align: start;
  }

  .year {
    font-family: $poppins;
    letter-spacing: 0.08em;
    margin: 0;
    padding: 0 2.6vw 2.6vw 0;
    font-size: 5rem;
    font-weight: bold;
    line-height: 97%;
    color: $light-orange;
    align-self: end;
  }
}

.content-wrapper {
  &.fade-out {
    animation: fade-out 0.3s forwards;
  }

  &.fade-in {
    animation: fade-in 0.3s forwards;
  }
}

@media only screen and (max-width: 767px) {
  .participants-wrapper {
    .title {
      font-size: 3.2rem;
    }
  }
}
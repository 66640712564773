@import "../../assets/styles/variables";

.gallery-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: $poppins;
  color: white;
  font-size: 24px;
  font-weight: 400;
  flex: 5;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  background-image: url("../../assets/images/diamond.png");
  background-position: center center;
  background-repeat: no-repeat;

  &.animate {
    animation: zoom-in 2s forwards;
  }

  .carrousel {
    position: relative;
    // left: 25vw;
    // top: 4vw;
    // display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 60vw;
    align-items: center;
    // overflow-x: hidden;
    // overflow-y: auto;
    overflow: hidden;
    height: 65%;
  }

  .scroll-container {
    div {
      width: 30px;
      height: 50px;
      border: 2px solid #fff;
      border-radius: 50px;
      box-sizing: border-box;
      position: relative;
    }
    div::before {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      content: "";
      width: 6px;
      height: 6px;
      margin-left: 11px;
      background-color: #fff;
      border-radius: 100%;
      box-sizing: border-box;
      -webkit-animation: sdb10 2s infinite;
      animation: sdb10 2s infinite;
    }
    .scroll-arrows {
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
    &.visible{
      visibility: visible;
    }
    
    &.invisible{
      visibility: hidden;
    }
  }
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    background-size: 30%;
  }
  100% {
    opacity: 1;
    background-size: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .gallery-wrapper {
    .carrousel {
      height: auto;
      max-width: 85vw;
    }
  }
  .scroll-container{
    display: none;
  }
}
